import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserSessionVerifierGuard } from './guards/user-session-verifier.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login-mode',
        pathMatch: 'full'
      },
      {
        path: 'login-mode',
        loadChildren: () => import('./pages/login-mode/login-mode-page.module').then((m) => m.LoginModePageModule),
        canActivate: [UserSessionVerifierGuard]
      },
      {
        path: 'internal',
        loadChildren: () => import('./pages/internal/internal-page.module').then((m) => m.InternalPageModule)
      },
      {
        path: 'external',
        loadChildren: () => import('./pages/external/external-page.module').then((m) => m.ExternalPageModule)
      },
      {
        path: '**',
        redirectTo: 'login-mode'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
